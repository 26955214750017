import router from '@/router';
import axios from 'axios';
import { createStore } from 'vuex'


export default createStore({
  state: {
    jwt: localStorage.getItem('jwt') || '',
    errorMsg: '',
    user: {},
    periodPayment: [],
    payment: [],
  },
  getters: {
    isAuhenticated: (state) => !!state.jwt,
    jwt: (state) => state.jwt,
    errorMsg: (state) => state.errorMsg,
    userMe: (state) => state.user,
    periodPayment: (state) => state.periodPayment,
    payment: (state) => state.payment,
    Authorization(state) {
      let jwt = state.jwt;
      return (jwt) ? `Token ${jwt}` : '';
    },
  },
  mutations: {
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setToken(state, jwt) {
      state.jwt = String(jwt);
      localStorage.setItem('jwt', jwt);
      state.errorMsg = '';
    },
    removeToken(state) {
      localStorage.removeItem('jwt');
      state.jwt = '';
    },
    setUser(state, value) {
      state.user = value;
    },
    setPeriodPayment(state, data) {
      state.periodPayment = data;
    },
    setPayment(state, data) {
      state.payment = data;
    },
  },
  actions: {
    fetchLogin(ctx, data) {
      axios.post(`/api/auth/token/`, data, {})
        .then((response) => {
          ctx.commit('setToken', response.data.token);
          router.push('/');
        })
        .catch((err) => {
          ctx.commit('setErrorMsg', 'Неверный логин или пароль');
        });
    },
    fetchUserMe(ctx) {
      axios.get(`/api/auth/user/me/`)
        .then((response) => {
          ctx.commit('setUser', response.data);
        })
    },

    fetchLogout(ctx) {
      ctx.commit('removeToken');
      ctx.commit('setUser', {});
      router.push('/');
    },

    fetchPeriodPayment(ctx, params) {
      const year = params.year;
      const month = params.month;
      axios.get(`/api/bill/period_payment/?year=${year}&month=${month}`)
        .then((response) => {
          ctx.commit('setPeriodPayment', response.data);
        })
        .catch((err) => { });
    },

    fetchPayment(ctx) {
      axios.get(`/api/bill/payment/`)
        .then((response) => {
          ctx.commit('setPayment', response.data);
        })
        .catch((err) => { });
    },

  },
  modules: {}
})



