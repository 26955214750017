import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-20843bd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "displayDate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!

  return (_openBlock(), _createBlock(_component_v_carousel, {
    modelValue: _ctx.slide,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.slide) = $event)),
    height: "100",
    "hide-delimiters": "",
    "show-arrows": ""
  }, {
    prev: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, {
        disabled: !_ctx.prevButton,
        color: "info",
        variant: "text",
        onClick: props.onClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.prevButton), 1)
        ]),
        _: 2
      }, 1032, ["disabled", "onClick"])
    ]),
    next: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, {
        disabled: !_ctx.nextButton,
        color: "info",
        variant: "text",
        onClick: props.onClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.nextButton), 1)
        ]),
        _: 2
      }, 1032, ["disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periodList, (period, i) => {
        return (_openBlock(), _createBlock(_component_v_carousel_item, { key: i }, {
          default: _withCtx(() => [
            _createVNode(_component_v_sheet, {
              color: "#3e3e3e",
              height: "30%"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.displayPeriod(period)), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}