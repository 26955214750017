import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_PaymentPeriod = _resolveComponent("PaymentPeriod")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_Payment = _resolveComponent("Payment")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_MainLayout = _resolveComponent("MainLayout")!

  return (_openBlock(), _createBlock(_component_MainLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_tabs, {
        class: "mb-5",
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "bg-color": "rgb(0, 71, 63)",
        color: "rgb(2, 188, 166)"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tab, { value: "1" }, {
            default: _withCtx(() => [
              _createTextVNode(" Квитанции ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_tab, { value: "2" }, {
            default: _withCtx(() => [
              _createTextVNode(" Настройка")
            ]),
            _: 1
          }),
          _createVNode(_component_v_tab, { value: "3" }, {
            default: _withCtx(() => [
              _createTextVNode("Динамика")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_tabs_window, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tabs_window_item, { value: "1" }, {
            default: _withCtx(() => [
              _createVNode(_component_PaymentPeriod)
            ]),
            _: 1
          }),
          _createVNode(_component_v_tabs_window_item, { value: "2" }, {
            default: _withCtx(() => [
              _createVNode(_component_Payment)
            ]),
            _: 1
          }),
          _createVNode(_component_v_tabs_window_item, { value: "3" }, {
            default: _withCtx(() => [
              _createVNode(_component_Chart)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}