<template>
    <v-carousel v-model="slide" height="100" hide-delimiters show-arrows>
        <template v-slot:prev="{ props }">
            <v-btn :disabled="!prevButton" color="info" variant="text" @click="props.onClick"> {{ prevButton }} </v-btn>
        </template>
        <template v-slot:next="{ props }">
            <v-btn :disabled="!nextButton" color="info" variant="text" @click="props.onClick"> {{ nextButton }} </v-btn>
        </template>
        <v-carousel-item v-for="(period, i) in periodList" :key="i">
            <v-sheet color="#3e3e3e" height="30%">
                <div class="displayDate">
                    {{ displayPeriod(period) }}
                </div>
            </v-sheet>
        </v-carousel-item>
    </v-carousel>
</template>


<script lang="ts">
import { PropType, defineComponent } from 'vue';
import Avatar from '@/components/Avatar.vue'
import { PeriodType } from '@/typing/main';
export default defineComponent({
    name: 'PeriodSlider',
    props: {
        periodList: {
            type: Object as PropType<PeriodType[]>,
            required: true,
        },
  },
    data() {
        return {
            slide: 0
        }
    },
    components: {
        Avatar,
    },
    mounted() {
        this.slide = this.periodList.length - 1;
    },
    computed: {
        prevButton() {
            if (this.periodList && this.slide > 0) {
                return this.displayByIndex(this.slide - 1);
            }
            return null
        },
        nextButton() {
            if (this.periodList && this.slide < this.periodList.length - 1) {
                return this.displayByIndex(this.slide + 1);
            }
            return null
        },
   },
   watch: {
        slide: function (val) {
            console.log('грузи бля');
            let period = this.periodList[val]
            console.log(period);
            this.$emit('loadPeriod', period)
        },
    },
    methods: {

        getMonth(i: number) {
            return [
                'Январь',
                'Февраль',
                'Март',
                'Апрель',
                'Май',
                'Июнь',
                'Июль',
                'Август',
                'Сентябрь',
                'Октябрь',
                'Ноябрь',
                'Декабрь',
            ][i - 1]
        },
        displayPeriod(period: any) {
            return `${this.getMonth(period.month)} ${period.year}`
        },
        displayByIndex(i: number) {
            return this.displayPeriod(this.periodList[i])
        },
    },
})
</script>


<style scoped>
.dark {
    color: white;
}

.displayDate {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>