<template>
  <v-app class="main-layout-custom">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: 'App',

  data() {
    return {}
  },
  created() {
  },
  methods: {
  },
})
</script>

<style>
.main-layout-custom {
  background-color: black;
  background-repeat: no-repeat;
  background-size: 400% 400%;
  background-position: center;
  background-image: url('@/assets/bgsvg_bills.svg');
}


@media (max-width: 1200px) {

}

a {
  text-decoration: none;
}


input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}


:root {
  --green-color: rgb(0, 180, 0);
  --green-color-hover: green;
  --yellow-color: #ffc107;
  --yellow-color-hover: #c59400;
  --red-color: #ff3535;
  --red-color-hover: #a82323;
}
</style>