<template>
  <div class="section_header">
    <div class="logo">
      <slot name="logo"></slot>
    </div>
    <div>
      {{ title }}
    </div>
    <div class="action">
      <slot name="action">  </slot>
    </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from "vue"


export default defineComponent({
  name: 'SectionHeader',
  props: {
    title: String,
  }
})
</script>


<style scoped>
.section_header {
  position: relative;
  margin-bottom: 20px;
  background-color: #00473f;
  background: linear-gradient(90deg, #00473f, #00473f 51%, transparent);
  color: white;
  padding: 16px;
  border-radius: 10px 10px 0px 0px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 20px;
  align-items: center;
}


.logo {
  height: 32px;
  color: var(--yellow-color);
}

.action {
  position: absolute;
  right: 0;
  top: 12;
}


@media (max-width: 768px) {
  .section_header {
    padding: 12px;
    font-size: 14px;
    background: unset;
    background-color: #00473f;
  }
  .logo {
    height: 24px;
    color: var(--yellow-color);
  }
}

</style>