<template>
    <RouterLink :to="{ path: '/profile' }">
      <Avatar :src="userMe.avatar" />
    </RouterLink>
</template>
  
  
<script lang="ts">
import { defineComponent } from "vue"
import Avatar from '@/components/Avatar.vue'
import { User } from '@/typing/main'



import { mapGetters } from "vuex";
export default defineComponent({
  name: 'HeaderUserMenu',
  data() {
    return {
      user: {} as User
    }
  },
  components: {
    Avatar,
  },
  computed: {
    ...mapGetters(['userMe'])
  },

})
</script>
  
  
<style scoped></style>