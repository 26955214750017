<template>
  <div class="rating">
    <CreatePayment method="create"  @accept="addRow">
      <template v-slot:activator="{ onClick }">
        <v-btn @click="onClick" class="addButton" color="#00473f" size="small"> + Квитанция </v-btn> 
      </template>
    </CreatePayment>
    <v-table class="full-height-table" fixed-header theme="dark">
      <thead>
        <tr>
          <th class="text-left">Название</th>
          <th class="text-left">Сумма по умолчанию</th>
        </tr>
      </thead>
      <tbody>
        <CreatePayment method="update" :payment="item" @accept="saveRow" v-for="item in payment" :key="`payment_${item.id}`">
          <template v-slot:activator="{ onClick }">
            <tr @click="onClick">
              <td> {{ item.name }} </td>
              <td> {{ item.default_amount }} </td>
            </tr>
          </template>
        </CreatePayment>
      </tbody>
    </v-table>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue"
import { mapGetters, mapActions } from "vuex";
import CreatePayment from "./uikit/CreatePayment.vue";
import { PaymentType} from '@/typing/main';
import axios from 'axios';

export default defineComponent({
  name: 'Payment',

  data() {
    return {
    }
  },
  components: {
    CreatePayment,
  },
  created() {
    console.log('[eeeee]');
    this.fetchPayment();
  },
  computed: {
    ...mapGetters(['payment']),
  },
  methods: {
    saveRow(data: PaymentType) {
      const body = {
        name: data.name,
        default_amount: String(data.default_amount) === "" ? null : data.default_amount,
      }
      axios.patch(`/api/bill/payment/${data.id}/`, body)
        .then(() => {
          this.fetchPayment();
        })
        .catch((err) => {
          console.log(err);
        });
      
    },
    addRow(data: PaymentType) {
      const body = {
        name: data.name,
        default_amount: String(data.default_amount) === "" ? null : data.default_amount,
      }
      axios.post(`/api/bill/payment/`, body)
        .then(() => {
          this.fetchPayment();
        })
        .catch((err) => {
          console.log(err);
        });
      
    },
    ...mapActions(['fetchPayment']),
    prettyTime(value: string) {
      const pad = (num: number) => String(num).padStart(2, '0');
      const dateTime = new Date(value);
      const now = new Date();
      const yearNow = now.getFullYear();
      const dateNow = now.getDate();
      const monthNow = now.getMonth();
      const year = dateTime.getFullYear();
      const date = dateTime.getDate();
      const month = dateTime.getMonth();
      const time = `${pad(dateTime.getHours())}:${pad(dateTime.getMinutes())}`
      if (yearNow === year && dateNow === date && monthNow && month) {
        return time;
      }
      return `${pad(date)}.${pad(month)}  ${time}`;
    },
  },
})
</script>


<style scoped>

tr {
  cursor: pointer;
}

.dark {
  color: white;
}

.rating {
  margin-bottom: 60px;
}

.full-height-table {
  height: calc(100dvh - 80px - 68px - 38px - 60px);
}

.addButton {
  margin-bottom: 10px;
}
</style>