import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-756c1d94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_MainLayout = _resolveComponent("MainLayout")!

  return (_openBlock(), _createBlock(_component_MainLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_SectionHeader, { title: "Мой профиль" }),
      _createVNode(_component_v_row, {
        dense: "",
        class: "mb-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            class: "c-10",
            cols: "12",
            md: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                theme: "dark",
                class: "pa-12 pb-8",
                elevation: "8",
                "max-width": "448",
                rounded: "lg"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_Avatar, {
                      src: _ctx.userMe.avatar
                    }, null, 8, ["src"])
                  ]),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.userMe.first_name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userMe.first_name) = $event)),
                    "bg-color": "transparent",
                    disabled: "",
                    label: "Имя",
                    variant: "underlined"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.userMe.username,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userMe.username) = $event)),
                    "bg-color": "transparent",
                    disabled: "",
                    label: "Логин",
                    variant: "underlined"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_ConfirmDialog, {
                    buttonText: "Выйти из профиля",
                    text: "Вы уверены что хотите выйти из системы?",
                    title: "Подвтердите действие",
                    onAccept: _ctx.fetchLogout
                  }, null, 8, ["onAccept"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}