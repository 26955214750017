<template>
  <div class="main_header">
      <RouterLink class="ontop" :to="{ path: '/' }">
        <div class="brand">
          <svg width="100%" height="100%" viewBox="0 0 1024 1024" class="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M182.87 146.29h585.14v292.57h73.14V73.14H109.72v877.72h402.29v-73.15H182.87z" fill="currentColor" /><path d="M713.14 481.52L512 582.1v88.24c0 110.29 62.31 211.11 160.95 260.43l40.19 20.09 40.19-20.09c98.64-49.32 160.95-150.14 160.95-260.43V582.1L713.14 481.52z m128 188.81c0 83.12-46.18 157.84-120.52 195.01l-7.48 3.74-7.48-3.74c-74.34-37.17-120.52-111.9-120.52-195.01V627.3l128-64 128 64v43.03z" fill="currentColor" /><path d="M657.87 683.21l-36.33 41.11 93.25 82.43 113.93-131.09-41.39-36L709.69 729zM256 256h438.86v73.14H256zM256 438.86h292.57V512H256zM256 621.71h146.29v73.14H256z" fill="currentColor" /></svg>
        </div>
      </RouterLink>
    <HeaderUserMenu />
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue"
import HeaderUserMenu from '@/components/HeaderUserMenu.vue'

export default defineComponent({
  name: 'MainHeader',

  data() {
    return {}
  },
  components: {
    HeaderUserMenu,
  },
  methods: {},

})
</script>


<style scoped>
.main_header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 30px;
  height: 80px;
}


.brand {
  height: 40px;
  width: 40px;
  color: #02bca6;
  width: auto;
}

.abs_center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.online_link {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 15px;
  color: var(--red-color);
  font-weight: bold;
  transition: 0.5s all ease;
}

@keyframes change-background {
  0% {
    background-color: rgb(0, 0, 0);
  }

  50% {
    background-color: red;
  }

  100% {
    background-color: rgb(0, 0, 0);
  }
}

.online_circle {
  background-color: var(--red-color);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  max-width: 12px;
  max-height: 12px;
  transition: 0.5s ease all;
  animation: change-background 4s ease infinite;
}


.online_link:hover {
  color: var(--yellow-color);
}

.ontop {
  z-index: 100;
}

@media (max-width: 768px) {
  .brand {
    height: 40px;
    width: auto;
  }
  .online_link {
  font-size: 14px;
  gap: 10px;
}
}


</style>