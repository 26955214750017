<template>
  <div class="rating">
    <CreatePeriod @accept="addPeriod"/>
    <PeriodSlider @loadPeriod="loadPeriod" v-if="periodList && periodList.length" :periodList="periodList"/>
    
    <v-table class="full-height-table" fixed-header theme="dark">
      <thead>
        <tr>
          <th class="text-left"></th>
          <th class="text-left">Квитанция</th>
          <th class="text-left">Сумма</th>
        </tr>
      </thead>
      <tbody>
        <UpdatePeriodPayment :periodPayment="item" @accept="saveRow" v-for="item in periodPayment" :key="`periodPayment_${item.id}`"/>
      </tbody>
    </v-table>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import Avatar from '@/components/Avatar.vue';
import { mapGetters, mapActions } from "vuex";
import PeriodSlider from "./PeriodSlider.vue";
import UpdatePeriodPayment from "./uikit/UpdatePeriodPayment.vue";
import CreatePeriod from "./uikit/CreatePeriod.vue";
import { PeriodPaymentType, PeriodType } from '@/typing/main';
import axios from 'axios';

export default defineComponent({
  name: 'PaymentPeriod',

  data() {
    return {
      periodList: [],
      model:0,
    }
  },
  components: {
    Avatar,
    PeriodSlider,
    UpdatePeriodPayment,
    CreatePeriod,
  },
  created() { 
    this.fetchPeriod()
  },
  computed: {
    ...mapGetters(['periodPayment']),
  },
  methods: {
    fetchPeriod() {
      axios.get(`/api/bill/period_list/`)
          .then((response) => {
              this.periodList = response.data;
          })
          .catch((err) => { });
    },
    ...mapActions(['fetchPeriodPayment']),
    addPeriod(data: PeriodType) {
      axios.post(`/api/bill/period_create/`, data)
        .then(() => {
          this.fetchPeriodPayment(data);
          this.fetchPeriod();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    saveRow(data: PeriodPaymentType) {
      const body = {
        status: data.status,
        amount: data.amount,
        presave: data.presave,
      }
      axios.patch(`/api/bill/period_payment/${data.id}/`, body)
        .then(() => {
        })
        .catch((err) => {
          console.log(err);
        });
      
    },
    loadPeriod(period: PeriodType) {
      this.fetchPeriodPayment(period)
    },
    prettyTime(value: string) {
      const pad = (num: number) => String(num).padStart(2, '0');
      const dateTime = new Date(value);
      const now = new Date();
      const yearNow = now.getFullYear();
      const dateNow = now.getDate();
      const monthNow = now.getMonth();
      const year = dateTime.getFullYear();
      const date = dateTime.getDate();
      const month = dateTime.getMonth();
      const time = `${pad(dateTime.getHours())}:${pad(dateTime.getMinutes())}`
      if (yearNow === year && dateNow === date && monthNow && month) {
        return time;
      }
      return `${pad(date)}.${pad(month)}  ${time}`;
    },
  },
})
</script>


<style scoped>

tr {
  cursor: pointer;
}

.dark {
  color: white;
}
.rating {
  margin-bottom: 60px;
}

.full-height-table {
  height: calc(100dvh - 80px - 68px - 38px - 60px - 100px);
}

</style>