<template>
    <v-dialog theme="dark" v-model="dialog" max-width="600">
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn class="addButton" color="#00473f" v-bind="activatorProps" size="small"> + Период </v-btn>  
      </template>

      <v-card title="Добавить период">
        <form v-on:submit.prevent="dialog = false; $emit('accept', period)">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <v-select :items="yearList" v-model="period.year" label="Год" item-title="label" item-value="value" required />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select :items="monthList" v-model="period.month" label="Месяц" item-title="label" item-value="value" required />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="Отмена" variant="plain" @click="dialog = false"></v-btn>
          <v-btn color="primary" text="Сохранить" variant="tonal" type="submit"></v-btn>
        </v-card-actions>
      </form>
      </v-card>
    </v-dialog>
</template>



<script lang="ts">
import { PeriodType } from '@/typing/main'
import { defineComponent } from 'vue';

export default defineComponent({
  name: "CreatePeriod",
  data() {
    return {
      dialog: false,
      yearList: [
        2022,
        2023,
        2024,
        2025,
      ],
      monthList: [
        {
          label: 'Январь',
          value: 1,
        },
        {
          label: 'Февраль',
          value: 2,
        },
        {
          label: 'Март',
          value: 3,
        },
        {
          label: 'Апрель',
          value: 4,
        },
        {
          label: 'Май',
          value: 5,
        },
        {
          label: 'Июнь',
          value: 6,
        },
        {
          label: 'Июль',
          value: 7,
        },
        {
          label: 'Август',
          value: 8,
        },
        {
          label: 'Сентябрь',
          value: 9,
        },
        {
          label: 'Октябрь',
          value: 10,
        },
        {
          label: 'Ноябрь',
          value: 11,
        },
        {
          label: 'Декабрь',
          value: 12,
        },
      ],
      period: {} as PeriodType,
    };
  },
});
</script>


<style scoped>

tr:hover {
  background-color: black;
}

tr {
  cursor: pointer;
}

.icon_status {
  width: 24px;
  height: 24px;
}

.addButton {
  margin-bottom: 10px;
}
</style>
