<template>
  <MainLayout>

    

    <SectionHeader title="Мой профиль"/>
    <v-row dense class="mb-4">
      <v-col class="c-10" cols="12" md="12">
        <v-card theme="dark" class="pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
          <div class="avatar">
            <Avatar :src="userMe.avatar" />
          </div>
          <v-text-field v-model="userMe.first_name" bg-color="transparent" disabled label="Имя"
            variant="underlined"></v-text-field>
          <v-text-field v-model="userMe.username" bg-color="transparent" disabled label="Логин"
            variant="underlined"></v-text-field>
          <ConfirmDialog 
            buttonText="Выйти из профиля" 
            text="Вы уверены что хотите выйти из системы?"
            title="Подвтердите действие"
            @accept="fetchLogout"/>
        </v-card>
      </v-col>
    </v-row>


  </MainLayout>
</template>

<script lang="ts">
import { mapGetters, mapActions } from "vuex";
import { defineComponent } from 'vue';
import MainLayout from '@/layouts/MainLayout.vue';
import Avatar from '@/components/Avatar.vue'
import ConfirmDialog from '@/components/uikit/ConfirmDialog.vue'
import SectionHeader from '@/components/SectionHeader.vue'

export default defineComponent({
  name: 'Profile',
  components: {
    MainLayout,
    Avatar,
    ConfirmDialog,
    SectionHeader,
  },
  data() {
        return {
        };
    },
  created() {
    this.fetchUserMe();
  },
  computed: {
    ...mapGetters(['userMe']),
  },
  methods: {
    ...mapActions(["fetchLogout", "fetchUserMe"]),
  }
});
</script>

<style scoped>
.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;
}

.user-info>span {
  font-size: 20px;
  color: white;
}

.avatar {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
</style>