import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, { to: { path: '/profile' } }, {
    default: _withCtx(() => [
      _createVNode(_component_Avatar, {
        src: _ctx.userMe.avatar
      }, null, 8, ["src"])
    ]),
    _: 1
  }))
}