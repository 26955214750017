<template>
  <MainLayout>

      <v-tabs 
      class="mb-5"
      v-model="tab"
      bg-color="rgb(0, 71, 63)"
      color="rgb(2, 188, 166)"
      >
      <v-tab value="1"> Квитанции </v-tab>
      <v-tab value="2"> Настройка</v-tab>
      <v-tab value="3">Динамика</v-tab>
    </v-tabs>
    
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="1">
          <PaymentPeriod/>
        </v-tabs-window-item>
        
        <v-tabs-window-item value="2">
          <Payment/>
        </v-tabs-window-item>
        
        <v-tabs-window-item value="3">
          <Chart/>
        </v-tabs-window-item>
      </v-tabs-window>
  
  </MainLayout>
</template>

<script lang="ts">
import { mapGetters, mapActions } from "vuex";
import { defineComponent } from 'vue';
import MainLayout from '@/layouts/MainLayout.vue';
import SectionHeader from '@/components/SectionHeader.vue'
import PaymentPeriod from "@/components/PaymentPeriod.vue";
import Payment from "@/components/Payment.vue";
import Chart from "@/components/Chart.vue";

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      tab: "1"
    }
  },
  components: {
    SectionHeader,
    PaymentPeriod,
    MainLayout,
    Payment,
    Chart,
  },
  created() {
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([]),
  }
});
</script>

<style scoped>

</style>