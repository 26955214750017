<template>
  <v-card class="mx-auto text-center mb-8" color="#212121" max-width="100%" dark>
    <v-card-text>
      <v-sheet color="black">
        <v-sparkline line-width="1" :labels="labels" :model-value="paymentChart" color="rgba(255, 255, 255, .7)" height="100" padding="24"
          stroke-linecap="round" auto-draw smooth>
          <template v-slot:label="item">
            {{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card-text>

    <v-card-text>
      <div class="text">
        Последние 6 месяцев
      </div>
    </v-card-text>

  </v-card>
</template>


<script lang="ts">
import { defineComponent } from "vue"
import axios from 'axios';

export default defineComponent({
  name: 'Avatar',
  props: {
    src: String || null || undefined,
  },
  data() {
    return {
      paymentChart: [
       
      ],
      labels: [
       
      ],

    }
  },
  created() {
    this.fetchPaymentChart()
  },
  components: {
  },
  methods: {
    fetchPaymentChart() {
            axios.get(`/api/bill/payment_chart/`)
                .then((response) => {
                  
                    this.paymentChart = response.data.map((value: any) => value.sum).reverse();
                    // this.labels = response.data.map((value: any) => `${value.month}.${value.year}: ${value.sum} Р `);
                    this.labels = response.data.map((value: any) => `${value.sum} Р `).reverse();
                })
                .catch((err) => { });
        },

  },

})
</script>


<style scoped></style>